import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecruitPro from "../modules/RecruitPro/RecruitPro"

const Recruit = () => (
  <Layout>
    <SEO title="Boost Your Placement" 
    description="Increase Your Placements, automate and Grow Business with Korero RecruitPro!"/>
    <RecruitPro />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default Recruit
