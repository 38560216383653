import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "./RecruitPro.css"
import axios from "axios"
import Logo from "../../components/Logo"
import playbtn_1 from "../../images/playbtn_1.jpg"
import Korero_RecruitPro_Thumbnail1 from "../../images/Korero_RecruitPro_Thumbnail1.jpg"
import { Component } from "react"
import ReactPlayer from "react-player"

class RecruitPro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countries: [],
      error: false,
      email: "",
    }
  }
  // handleEmailChange = (evt) => {
  //   this.setState({ email: evt.target.value })
  // }
  // handleSubmit = () => {
  //   const { email } = this.state
  //   alert(`Signed up with email: ${email} `)
  // }
  componentDidMount() {
    axios
      .get(`https://appbackend.tekorero.com/api/v2.0/countries`)
      .then((res) => {
        this.setState({ countries: res.data })
      })
      .catch((err) => {})
  }
  changeHandler = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
  }
  submitHandler = (name, email, phone, country) => {
    if (!name) {
      this.setState({ error: true })
      alert("Input needed for Name, Email, Phone Number and Country")
    } else if (!email) {
      this.setState({ error: true })
      alert("Input needed for Name, Email, Phone Number and Country")
    } else if (!phone) {
      this.setState({ error: true })
      alert("Input needed for Name, Email, Phone Number and Country")
    } else if (!country) {
      this.setState({ error: true })
      alert("Input needed for Name, Email, Phone Number and Country")
    } else {
      this.setState({ error: false })
    }
  }

  render() {
    const { name, email, phone, companyName, country, error } = this.state
    // const isEnabled = email.length > 0
    const url = `http://localhost:3000/download/contactUs?name=${name}&email=${email}&phone=${phone}&companyName=${companyName}&country=${country}`
    return (
      <>
        {/* <form onSubmit={this.handleSubmit}> */}
        <div className="main_body">
          <div className="box_border">
            <div className="contents">
              <div className="video_content_left">
                <div class="testing">
                  <ReactPlayer
                    url="https://youtu.be/qJI9t8UBp_o"
                    width="100%"
                    // height="100%"
                  />
                </div>
                <div className="texts">
                  <h1 style={{ fontSize: "18px" }}>
                    Increase Your Placements and Grow Business with Korero
                    RecruitPro!{" "}
                  </h1>
                  <h2 style={{ fontSize: "14px" }}>
                    Automate Smarter & Faster
                  </h2>
                  <h2 style={{ fontSize: "14px" }}>
                    Manage the lifecycle of a profile from submission to offer
                  </h2>
                </div>
              </div>

              <div className="form_content_right">
                <div className="korero_logo">
                  <Logo />
                </div>
                <div className="letter">
                  <center>
                    Please fill out the form below to download the white paper
                  </center>
                </div>
                <div className="form">
                  <div className="label">Name*</div>
                  <div className="text_box">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={this.changeHandler}
                    ></input>
                  </div>

                  <div className="label">Email*</div>
                  <div className="text_box">
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                    ></input>
                  </div>

                  <div className="label">Company</div>
                  <div className="text_box">
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={companyName}
                      onChange={this.changeHandler}
                    ></input>
                  </div>
                  <div className="label">Country*</div>
                  <select
                    name="country"
                    onChange={this.changeHandler}
                    value={country}
                    required
                    style={{
                      width: "100%",
                      fontSize: "14px",

                      height: "33px",
                      border: "1px solid #35c9fe",
                      fontFamily: "Roboto",
                    }}
                  >
                    {this.state.countries.map((country) => {
                      return (
                        <>
                          <option value="" disabledselected hidden>
                            Select
                          </option>
                          <option value={country.countryName} class="others">
                            {country.countryName}
                            {` ${country.countryDialCode}`}
                          </option>
                        </>
                      )
                    })}
                  </select>
                  <div className="label">Phone*</div>
                  <div className="text_box">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={this.changeHandler}
                    ></input>
                  </div>
                </div>

                <div className="button_whitepaper">
                  <a
                    href={
                      !error
                        ? `https://backend.tekorero.com/download/contactUs?name=${name}&email=${email}&phone=${phone}&companyName=${companyName}&country=${country}`
                        : "#"
                    }
                  >
                    <button
                      // disabled={!isEnabled}
                      type="submit"
                      className="button_white"
                      onClick={() =>
                        this.submitHandler(name, email, phone, country)
                      }
                    >
                      Click here to Download
                    </button>
                  </a>
                </div>
                <div className="spacer"></div>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </>
    )
  }
}
export default RecruitPro
